<template>
  <div class="favorites">
    <top-bar />
    <page-title title="Избранные товары" />
    <n-loader :loading="$var('loading')" />
    <div class="content">
      <div class="container">
        <div v-if="favorites.length" class="wrap">
          <c-product-card v-for="product in favorites" :key="product.id" :product="product" @like="load" />
        </div>
        <div v-else class="info">Вы пока не выбрали ни одного товара...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Favorites',
  data() {
    return {
      favorites: [],
    }
  },
  watch: {
    '$store.state.app.likes'(value) {
      if (value.length !== this.favorites.length) {
        this.load()
      }
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const likes = $app.store.state('app.likes')
      this.$var('loading', true)
      const ids = 'in:'+likes.join(',')
      $api.app.products.get().all().filter({ id: ids, }).then((response) => {
        this.favorites = response.data.content
        $app.store.mutation('app.likes', $n.reduce(this.favorites, (result, item) => {
          result.push(item.id)
          return result
        }, []))
      }).finally(() => {
        this.$var('loading', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.favorites{
  .content{
    position: relative;

    &::v-deep .n-loader .n-line{
      top: -15px;
    }
  }
  .wrap{
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    .c-product-card{
      padding: 5px;
    }
  }

  .info{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
